<template>
  <div class="menuManagement">
    <el-scrollbar class="elScroll">
      <div class="table_sList">
        <el-tabs v-model="activeName">
          <el-tab-pane label="菜单管理" name="first"></el-tab-pane>
        </el-tabs>
        <el-table
          border
          :data="tableData"
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildrens'}">
          <el-table-column prop="name" label="菜单名称" :show-overflow-tooltip="true" width="180"></el-table-column>
          <!-- <el-table-column prop="icon" label="图标" align="center" width="100">
            <template slot-scope="scope">
              <i :class="scope.row.icon"/>
            </template>
          </el-table-column> -->
          <el-table-column prop="sort" label="排序" width="60"></el-table-column>
          <el-table-column prop="path" label="组件路径" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="type" label="类型" width="80" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.type === '0'">左菜单</el-tag>
              <el-tag type="success" v-if="scope.row.type === '2'">顶菜单</el-tag>
              <el-tag type="info" v-if="scope.row.type === '1'">按钮</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="keepAlive" label="缓冲" width="80" align="center">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.keepAlive === '0'">关闭</el-tag>
              <el-tag type="success" v-if="scope.row.keepAlive === '1'">开启</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="permission" label="权限标识" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                icon="el-icon-plus"
                @click="addOrUpdateHandle(false,scope.row.id)" v-allow="'sys_menu_add'">新增</el-button>
                <!-- v-allow="'sys_menu_add'" -->
              <el-button size="small"
                type="text"
                icon="el-icon-edit"
                @click="addOrUpdateHandle(true,scope.row.id)" v-allow="'sys_menu_edit'">修改</el-button>
                <!-- v-allow="'sys_menu_edit'" -->
              <el-button
                size="small"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)" v-allow="'sys_menu_del'">删除</el-button>
                <!-- v-allow="'sys_menu_del'" -->
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-table
          :data="tableData"
          stripe
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%"
          default-expand-all
          align="center"
          row-key='id'>
          <el-table-column
            prop="name"
            label="菜单名称"
            align="center"
            width="120">
          </el-table-column>
          <el-table-column
            prop="icon"
            label="图标"
            align="center"
            width="100">
          </el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            width="140">
          </el-table-column>
          <el-table-column
            prop="path"
            label="组件路径"
            align="center"
            width="100">
          </el-table-column>
          <el-table-column
            prop="stationName"
            label="类型"
            align="center"
            width="100">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="缓冲"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="unitPrice"
            label="权限标识"
            align="center">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="200"
            align="center">
            <template slot-scope="scope">
              <el-button type="text" slot="reference" @click="watchState(scope.row)">新增</el-button>
              <el-button type="text" slot="reference" @click="watchState(scope.row)">查看详情</el-button>
              <el-button @click="deleteEvent(scope.row)" type="text" v-allow="'sys_order_del'">删除</el-button>
            </template>
          </el-table-column>
        </el-table> -->
        <!-- <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination> -->
      </div>
    </el-scrollbar>
    <table-form v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getMenuListData"></table-form>
  </div>
</template>

<script>
import TableForm from './menu-form'
export default {
  name: 'menuManagement',
  components: {
    TableForm
  },
  data() {
    return {
      activeName: 'first',
      loading: false,
      tableData: [],
      addOrUpdateVisible: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.name == 'menuManagement') {
          this.getMenuListData();
        }
      }
    }
  },
  methods: {
    getMenuListData() {
      this.loading = true;
      this.$request.fetchMenuTree({lazy: false}, res=>{
        this.loading = false;
        if(res.code == 0) {
          this.tableData = res.data;
        }
      })
    },
    addOrUpdateHandle(isEdit, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(isEdit, id)
      })
    },
    handleDelete(row) {
      let that = this;
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return that.$request.delMenuObj(row.id);
      }).then(() => {
        this.getMenuListData();
        this.$message.success('删除成功')
      })
    }
  },
}
</script>

<style lang="less">
  .menuManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .table_sList {
        box-sizing: border-box;
        padding: 30px 40px;
        margin-top: 10px;
        background-color: #fff;
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #fff;
        }
        ::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: rgba(0, 0, 0, .1);
            border-radius: 3px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
            border-right: none;
        }
        .el-pagination {
          text-align: right;
        }
        .btns {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
